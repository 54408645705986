import MainLayout from '../../layouts/MainLayout'
import { HCAType, IWidgetInfo } from '../../interfaces/IWidgetInfo'
import { useState, useEffect } from 'preact/hooks'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'
import { AttendanceStatus } from '../../interfaces/AttendanceStatus'
import { useNotifications } from '../../hooks/useNotifications'
import { Text, useText } from 'preact-i18n'

dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(relativeTime)

import calendar from '../../assets/emptystate/calendar.png'
import calendarClock from '../../assets/emptystate/calendar-clock.png'
import medal from '../../assets/emptystate/cettificate.png'
import rocket from '../../assets/emptystate/rocket.png'
import clock from '../../assets/emptystate/clock.png'
import team from '../../assets/emptystate/team.png'
import { useAttendance } from '../../hooks/useAttendance'
import styled from 'styled-components'
import { colors } from '../../style/variables'
import { BlueButton } from '../../components/Buttons/BlueButton'

type IProps = {
    widgetInfo: IWidgetInfo
}

type IEmptyState = {
    icon?: any
    title: string | JSX.Element
    msg: string | JSX.Element,
    description?: string | JSX.Element,
    refresh?: boolean
    setPresent?: boolean
    notificateCoach?: boolean
    joinMeeting?: boolean,
    action?: 'notificate_coach' | 'notificated_coach' | 'set_present' | 'join_meeting'
}

const UnassignedView = ({ widgetInfo }: IProps) => {
    
    
    const [emptyState, setEmptyState] = useState<IEmptyState>({ title: '', msg: '' })
    const { sendNotification } = useNotifications()
    const [coachNotificated, setCoachNotificated] = useState<boolean>(false)
    const { markAttendance } = useAttendance()

    const { ongoingClass, pleaseWait } = useText({
        ongoingClass: 'attendance.day-late.title',
        pleaseWait: 'attendance.please-wait'
    })


    // Wait for unassigned to be updated and get current empty state case
    useEffect(() => {
        getUnassignedCase()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetInfo])


    const getUnassignedCase = () => {
        // eslint-disable-next-line no-debugger
        const now = dayjs().valueOf()
        const start = dayjs(widgetInfo?.team?.nextClass?.start).valueOf()
        const end = dayjs(widgetInfo?.team?.nextClass?.end).valueOf()

        const startDatePresent = dayjs(widgetInfo?.team.nextClass?.start).format('HH:mm')
        const startLessTolerance = dayjs(widgetInfo.team?.nextClass.start)
            .subtract(widgetInfo.team?.nextClass?.attendancesRegistrationTime?.attendancesStartTime, 'minutes')
            .format('HH:mm')

        const presentBeforeStart = dayjs(widgetInfo?.team?.nextClass?.start)
            .subtract(widgetInfo?.team.nextClass?.attendancesRegistrationTime.attendancesStartTime, 'minutes')
            .valueOf()
        const startPlusTolerance = dayjs(widgetInfo?.team?.nextClass?.start)
            .add(widgetInfo?.team?.nextClass?.attendancesRegistrationTime.attendancesEndTime, 'minutes')
            .valueOf()

        const startHour = dayjs(widgetInfo.team?.nextClass?.start).format('HH:mm')
        const startPlusToleranceFormatted = dayjs(widgetInfo.team?.nextClass.start)
            .add(widgetInfo.team?.nextClass?.attendancesRegistrationTime?.attendancesEndTime, 'minutes')
            .format('HH:mm')

        const isEarly = () => now < start
        const isToday = () => dayjs(start).isToday()
        const isInRange = () => dayjs(now).isBetween(presentBeforeStart, startPlusTolerance)
        const isInClass = () => dayjs(now).isBetween(startPlusTolerance, end)

        if (widgetInfo.team.settings.defaultShouldCheckPresents) {
            /**  * Checks if start date for progressive courses has already passed */
            if (widgetInfo.team.settings.algorithmType === HCAType.HCA4) {
                if (!widgetInfo.team.startDate?.date && widgetInfo.team.startDate?.enabled === false) {
                    /**  Si hay una fecha de inicio mayor a hoy  */
                    setEmptyState({
                        icon: calendar,
                        title: <Text id="attendance.no-day-set.title">Aquí encontrarás a tu equipo</Text>,
                        msg: <Text id="attendance.no-day-set.desc"></Text>,
                    })
                    return
                }
            }

            /**  * Checks if start date for progressive courses has already passed */
            if (widgetInfo.team.settings.algorithmType === HCAType.HCA4) {
                if (widgetInfo.team.startDate?.enabled === false) {
                    /**  Si hay una fecha de inicio mayor a hoy  */
                    setEmptyState({
                        icon: calendar,
                        title: <Text id="attendance.start-date.title"></Text>,
                        msg: (
                            <Text
                                id="attendance.start-date.desc"
                                fields={{
                                    day: dayjs(widgetInfo.team?.startDate.date).format('DD'),
                                    month: dayjs(widgetInfo.team?.startDate.date).format('MMMM'),
                                }}
                            ></Text>
                        ),
                    })
                    return
                }
            }

            if (widgetInfo.team.settings.algorithmType === HCAType.HCA4) {
                if (widgetInfo.team.endDate?.enabled === false) {
                    /**  Si hay una fecha de inicio mayor a hoy  */
                    setEmptyState({
                        icon: medal,
                        title: <Text id={'completed-course.title'}>Tu curso ha finalizado</Text>,
                        msg: <Text id={'completed-course.description'}>Gracias por formar parte de nuestra Comunidad</Text>,
                        description: <Text id={'completed-course.message'}>Esperamos verte pronto.</Text>,
                    })
                    return
                }
            }

            if (widgetInfo?.team.nextClass) {
                if (isEarly() && isToday() && !isInRange()) {
                    /**  Si todavía no comienza */
                    console.log(widgetInfo?.team.nextClass?.start)
                    setEmptyState({
                        icon: clock,
                        title: <Text id="attendance.early-day-noRange.title">Llegaste a tiempo</Text>,
                        msg: (
                            <Text
                                id="attendance.early-day-noRange.desc"
                                fields={{ start: startDatePresent, startLessTolerance: startLessTolerance }}
                            />
                        ),
                    })
                } else if (isToday() && isInRange()) {
                    /* If the class is today and its between the start hour and the tolerances */
                    if (widgetInfo.me.own?.attending === AttendanceStatus.UNSET) {
                        /** Si ya comenzó pero está dentro del limite de tolerancia  */
                        setEmptyState({
                            icon: clock,
                            title: <Text id="attendance.early-day-range.title"> ¡Hola! Gracias por llegar a tiempo. </Text>,
                            msg: (
                                <Text
                                    id="attendance.early-day-range.desc"
                                    fields={{ start: startHour, startPlusTolerance: startPlusToleranceFormatted }}
                                />
                            ),
                            action: 'set_present',
                        })
                    }
                } else if (isToday() && isInClass()) {
                    /** Si ya comenzó pero todavía no termina  */
                    if (widgetInfo.team.settings.automaticAssignAbsents) {
                        setEmptyState({
                            icon: rocket,
                            title: <Text id="attendance.day-late.title">La clase ya comenzó</Text>,
                            msg: '',
                            action: 'join_meeting',
                        })
                    } else {
                        setEmptyState({
                            icon: rocket,
                            title: <Text id="attendance.day-late.title">La clase ya comenzó</Text>,
                            msg: '',
                            action: 'notificate_coach',
                        })
                    }
                } else {
                    /**  Si es otro día  */
                    setEmptyState({
                        icon: calendarClock,
                        title: <Text id="attendance.another-day.title">Tu encuentro está programado</Text>,
                        msg: (
                            <Text
                                id="attendance.another-day.desc"
                                fields={{ start: dayjs(widgetInfo.team?.nextClass?.start).format('dddd HH:mm') }}
                            />
                        ),
                    })
                }
            } else {
                /**  Si NO hay próxima clase configurada  */
                setEmptyState({
                    icon: team,
                    title: <Text id="attendance.no-day-set.title">Aquí encontrarás a tu equipo</Text>,
                    msg: <Text id="attendance.no-day-set.desc"></Text>,
                })
                return
            }
        } else {
            setEmptyState({
                icon: team,
                title: <Text id="attendance.no-day-set.title">Aquí encontrarás a tu equipo</Text>,
                msg: <Text id="attendance.no-day-set.desc"></Text>,
            })
            return
        }
    }

    const notificateCoach = () => {
        sendNotification(
            'Nueva solicitud de ingreso',
            `El usuario ${widgetInfo.me.name} ${widgetInfo.me.lastname}, del equipo ${widgetInfo.team.course.name} en ${widgetInfo.team.hq.name} solicita ingresar al evento.`
        ).then(() => {
            markAttendance().then(() => {
                setCoachNotificated(true)
                setEmptyState({
                    icon: rocket,
                    title: ongoingClass,
                    msg: pleaseWait,
                    action: 'notificate_coach',
                })
            })
        })
    }

    const getAttendanceText = (value: AttendanceStatus) => {
        switch (value) {
            case AttendanceStatus.UNSET:
                return <Text id="attendance.here">Entrar al encuentro</Text>
            case AttendanceStatus.PRESENT:
                return <Text id="attendance.in-time">Presente</Text>
            case AttendanceStatus.DELAY:
                return <Text id="attendance.delay">Tardanza</Text>
            case AttendanceStatus.ABSENT:
                return <Text id="attendance.absent">Ausente</Text>
        }
    }

    const getActionBtn = (action: IEmptyState['action']) =>{
        switch(action){
            case 'notificate_coach': 
                return(
                    <BlueButton
                        disabled={coachNotificated}
                        onClick={() => notificateCoach()}
                        style={{width: 'fit-content', borderRadius: 12, marginTop: 40, marginLeft: 'auto', marginRight: 'auto' }}
                    >
                        {!coachNotificated ? <Text id="attendance.notificate-coach">Dar el aviso</Text> : <Text id="attendance.coach-notificated">Coach notificado</Text>}
                    </BlueButton>
                )
            case 'set_present':
                return(

                    <BlueButton
                        attendance={widgetInfo?.me?.own?.attending} 
                        title="Marcar presente"
                        disabled={widgetInfo?.me?.own?.attending !== AttendanceStatus.UNSET}
                        onClick={() => markAttendance()}
                        style={{width: 'fit-content', borderRadius: 12, marginTop: 40, marginLeft: 'auto', marginRight: 'auto'}}
                    >
                         {widgetInfo?.me.own && (
                            <span>{getAttendanceText(widgetInfo.me.own.attending)}</span>
                        )}
                    </BlueButton>
                )
            case 'join_meeting':
                return (
                    <BlueButton
                        disabled={coachNotificated}
                        onClick={() => markAttendance()}
                        style={{ width: 'fit-content', borderRadius: 12, marginTop: 40, marginLeft: 'auto', marginRight: 'auto' }}
                    >
                        Entrar al encuentro
                    </BlueButton>
                )
        }
    } 

    

    return (
        <MainLayout centered={true}>
            <div style={{ textAlign: 'center', padding: '0 40px' }}>
                { emptyState?.icon && (<img style={{ height: '102px', marginBottom: 30 }} src={emptyState.icon} alt={'An icon of a rocket'}/> )}
                <TitleStyled>{emptyState.title}</TitleStyled>
                <DescriptionStyled> { emptyState.msg } </DescriptionStyled>
                { emptyState?.action && getActionBtn(emptyState.action) }
                { emptyState?.description && <DescriptionStyled>{ emptyState.description }</DescriptionStyled> }
            </div>
        </MainLayout>
    )
}

export default UnassignedView

const TitleStyled = styled.h2`
    margin-top: 0px;
    font-size: 1rem;
    font-weight: 600;
    color: ${ colors.primary400 };
    margin-bottom: 18px;
`


const DescriptionStyled = styled.p`
    font-size: 0.875rem;
    color: ${ colors.gray500 };
    line-height: 22px;
    margin: 0px
`
