import { IWidgetInfo, TablePos } from '../../interfaces/IWidgetInfo'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useRef, useState } from 'preact/hooks'

import { AppContext } from '../../app'
import { AvatarEggStyled } from './AvatarEggStyled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactGA from 'react-ga4'
import { UIContext } from '../../context/UIContext'
import useUser from '../../hooks/useUser'

type IAvatarEggProps = {
    src?: string
    badge?: any
    title?: string
    facilitator?: boolean
    hasVoted?: boolean
    size?: number
    style?: Record<string, any>
    animate?: boolean
    isMe?: boolean
    handleFileSelect?: (e: any) => void
    bgColor?: any
    hasTooltip?: boolean
    isEditable?: boolean
    isMentor?: boolean
    user?: TablePos | undefined
} & Partial<IWidgetInfo['me']>

const AvatarEgg = (props: IAvatarEggProps) => {
    const { src, title, hasTooltip, isEditable, name, lastname, _id, urlImage } = props
    const avatarImgRef = useRef<any>(null)
    const [source, setSource] = useState<string | undefined>(src ?? urlImage)
    const fileInput = useRef<HTMLInputElement>(null)
    const [imageIsLoading, setImageIsLoading] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)
    const { updateUser } = useUser(props.user?._userId._id || '')
    const { dispatchNotification } = useContext(UIContext)
    const { widgetInfo } = useContext(AppContext)
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token')

    const handleFileSelect = (event: any) => {
        ReactGA.event({
            category: 'Profile',
            action: 'EditPictureClicked',
        })
        const selectedFile = event.target.files?.[0]
        if (!selectedFile) return
        handleUploadPhoto(selectedFile)
    }

    const handleUploadPhoto = async (fileToUpload: Blob) => {
        try {
            setImageIsLoading(true)
            const formData = new FormData()
            formData.append('file', fileToUpload)
            const response = await fetch(`${process.env.PREACT_APP_S3}/${widgetInfo?.me._id}`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `${token}`,
                },
            })
            const json = await response.json()
            await updateUser({ urlImage: `${process.env.PREACT_APP_IMAGE_S3}/${json.thumbnailKey}` })
            setSource(`${process.env.PREACT_APP_IMAGE_S3}/${json.thumbnailKey}`)
        } catch (error) {
            console.log('Error uploading image', error)
            dispatchNotification({
                type: null,
                payload: {
                    title: 'Error',
                    subtitle: 'Failed to upload image. Try again later.',
                },
            })
        } finally {
            setImageIsLoading(false)
        }
    }

    // Update src if src changes
    useEffect(() => {
        setSource(src ?? urlImage)
    }, [src, urlImage])

    return (
        <AvatarEggStyled
            title={title ?? (name && lastname) ? `${name} ${lastname}` : ''}
            onMouseOver={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            isEditabe={props.isMe}
            imageIsLoading={imageIsLoading}
            {...props}
        >
            <input style={{ display: 'none' }} type="file" onChange={handleFileSelect} ref={fileInput} />
            <div className="main">
                {!source ? (
                    <span className="gravatar">
                        {(title ?? name)
                            ?.split(' ')
                            .map(word => word.slice(0, 1))
                            .join('')
                            .toUpperCase()
                            .substring(0, 2)}
                    </span>
                ) : (
                    <img
                        src={source}
                        alt={title ?? (name && lastname) ? `${name} ${lastname}` : ''}
                        ref={avatarImgRef}
                        onError={() => avatarImgRef.current && (avatarImgRef.current.src = setSource(undefined))}
                    />
                )}
            </div>
            {props.facilitator && (
                <div className="badge facilitator">
                    <FontAwesomeIcon className="icon" icon={['fas', 'medal']} />
                </div>
            )}
            {props.isEditable && (
                <div className="badge image-upload">
                    <FontAwesomeIcon className="icon" icon={['fas', 'camera']} />
                </div>
            )}
            {props.isMentor && (
                <div className="badge isMentor">
                    <FontAwesomeIcon className="icon" icon={['fas', 'graduation-cap']} />
                </div>
            )}
            {hasTooltip && showTooltip && <div className="tooltip">{title?.substring(0, 13)}</div>}
            {(props.isEditable || imageIsLoading) && (
                <button onClick={() => fileInput.current?.click()} aria-label="Upload profile image" className="overlay">
                    {imageIsLoading ? (
                        <FontAwesomeIcon icon={['fal', 'spinner']} spin />
                    ) : (
                        <span>
                            Cargar
                            <br />
                            Imagen
                        </span>
                    )}
                </button>
            )}
        </AvatarEggStyled>
    )
}

export default AvatarEgg
