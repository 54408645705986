import axios from 'axios'
import { useContext } from 'preact/hooks'
import { setGAWidgetCancelHelp, setGAWidgetRequestHelp, setGAErrorHasOccurred, setGAWidgetReturnMentor } from '../helpers/GAnalytics'
import { AppContext } from '../app'
import { UIContext } from '../context/UIContext'
import { HelpRequestStatus, IWidgetInfo } from '../interfaces/IWidgetInfo'
import dayjs from 'dayjs'

export const useHelpRequest = () => {
    const { widgetInfo, setWidgetInfo, updateInfo, clearCache } = useContext(AppContext)
    const { setRequestNotification, dispatchNotification } = useContext(UIContext)
    const isMentor = widgetInfo?.algorithm?.isMentor
    const isFacilitador = widgetInfo?.table?.facilitator === widgetInfo?.me.profile._id ? true : false
    const { mainTimerRestart } = useContext(UIContext)

    let sound: any | HTMLAudioElement

    if (typeof window !== 'undefined') {
        if (!sound) {
            sound = new Audio()
        }
    }

    const requestHelp = async () => {
        try {
            const res = await axios.post(`${process.env.PREACT_APP_WIDGET_API}/v1/help-request`, {
                tableNumber: widgetInfo?.table?.tableNumber,
            })
            updateInfo({ table: { ...widgetInfo?.table, helpRequest: res.data.data.helpRequest } })
            setRequestNotification({ status: 'requested' })

            setGAWidgetRequestHelp({
                isMentor,
                isFacilitador,
                idTrakingHelpRequest: res.data.data.helpRequest._id,
            })

            //ejecutamos el audio
            sound.src = '../assets/audios/alert_3_up_Pedido_de_ayuda_activado.mp3'
            sound.play()

            return res
        } catch (error) {
            // setToast(['error', 'Ocurrió un error al solicitar ayuda'])
            dispatchNotification({
                type: null,
                payload: {
                    title: 'Error',
                    subtitle: 'Ocurrió un error al solicitar ayuda',
                },
            })
            console.log(error, 'no se puedo realizar la peticion')
            clearCache()
            setGAErrorHasOccurred('Error requesting help', error)
        }
    }

    const cancelHelp = async () => {
        mainTimerRestart(dayjs().add(50, 's').toDate(), false)
        try {
            const res = await axios.patch(`${process.env.PREACT_APP_WIDGET_API}/v1/help-request/close`, {
                status: 'canceled',
                tableNumber: widgetInfo?.table?.tableNumber,
            })
            updateInfo({ table: { ...widgetInfo?.table, helpRequest: res.data.data.helpRequest } })
            setRequestNotification({ status: 'canceled' })
            setGAWidgetCancelHelp(widgetInfo?.table?.helpRequest?._id)

            //ejecutamos sonido de cancelacion
            sound.src = '../../assets/audios/alert_3_down_Pedido_ayuda_cancelado.mp3'
            sound.play()

            return res
        } catch (error) {
            // setToast(['error', 'Ocurrió un error al cancelar la ayuda'])
            dispatchNotification({
                type: null,
                payload: {
                    title: 'Error',
                    subtitle: 'Ocurrió un error al cancelar la ayuda',
                },
            })
            console.log(error)
            clearCache()
            setGAErrorHasOccurred('Error cancelling help request', error)
        }
    }

    // Endpoint to process input from mentor to accept decline or expire an automated help request
    const mentorInput = async (input: 'accept' | 'decline' | 'expired') => {
        // console.log('input', input)
        try {
            await axios.post(`${process.env.PREACT_APP_WIDGET_API}/v1/help-request/${widgetInfo?.table?.helpRequest?._id}/mentorBehavior`, {
                behavior: input,
            })

        } catch (error: any) {
            updateHelpRequest(null)
            // setToast(['error', error.response.data.message === 'El pedido de ayuda fue cancelado' ? 'Pedido de ayuda cancelado. Ya no se requiere mentoría.' : 'Ocurrió un error en el pedido de ayuda'])
            dispatchNotification({
                type: null,
                payload: {
                    title: 'Pedido de ayuda cancelado',
                    subtitle:
                        error.response.data.message === 'El pedido de ayuda fue cancelado'
                            ? 'Pedido de ayuda cancelado. Ya no se requiere mentoría.'
                            : 'Ocurrió un error en el pedido de ayuda',
                },
            })
        }
    }

    const callNewMentor = async (_id: string) => {
        try {
            console.log('calling new mentor with hr _id: ', _id)
            await axios.patch(`${process.env.PREACT_APP_WIDGET_API}/v1/help-request/${_id}/resend`)
        } catch (error) {
            // setToast(['error', 'Ocurrió un error al salir del equipo'])
            console.log('Failed to call new mentor') // console.log(error)
        }
    }

    const returnMentor = async (callback?: () => any) => {
        try {  
            const { data } = await axios.post(`${process.env.PREACT_APP_WIDGET_API}/v1/algorithm/${widgetInfo?.algorithm?._id}/return-mentor`)
            setGAWidgetReturnMentor(
                data.data._helpRequestId, 
                widgetInfo?.table?.isExpertMentorTeam
            )
            callback && callback()
        } catch (error) {
            dispatchNotification({
                type: null,
                payload: {
                    title: 'Error',
                    subtitle: 'Ocurrió un error al salir del equipo',
                },
            })
            console.log(error)
        }
    }

    const updateHelpRequest = (update: { from: string; status: HelpRequestStatus; _id: string } | null) => {
        // @ts-ignore
        widgetInfo &&
            setWidgetInfo?.((prev: any) => {
                if (prev) {
                    return {
                        ...prev,
                        table: {
                            ...prev.table,
                            helpRequest: update,
                        },
                    }
                }
            })
    }

    return { requestHelp, cancelHelp, callNewMentor, returnMentor, mentorInput, updateHelpRequest }
}