import { IUserPatch, IWidgetInfo } from '../interfaces/IWidgetInfo'
import axios from 'axios'
import { AppContext} from '../app'
import { useContext } from 'preact/hooks'

const useUser = (userId: string) => {

    const { widgetInfo, updateInfo } = useContext(AppContext)

    const updateUser = async (data: any) => {
        updateInfo({ me: { ...widgetInfo?.me, urlImage: data.urlImage }})
        await axios.patch(`${process.env.PREACT_APP_WIDGET_API}/v1/user/student`, data)
    }

    return { updateUser }

}

export default useUser