import MainLayout from '../../layouts/MainLayout'
import { useContext, useEffect, useState } from 'preact/hooks'
import { AppContext } from '../../app'
import Axios from 'axios'
import styled from 'styled-components'
import { SecondaryTitle, LeadTextBlock } from '../../components/Type/Title'
import { BlueButton } from '../../components/Buttons/BlueButton'
import { WhiteButton } from '../../components/Buttons/WhiteButton'
import { colors } from '../../style/variables'
import ReactGA from 'react-ga4'
import { Text } from 'preact-i18n'
import meetIconOk from '../../assets/meet_icon_ok.svg'
import meetIconErr from '../../assets/meet_icon_err.svg'
import { useNavigation } from '../../hooks/useNavigation'

interface Props {}

const UserView = (props: Props) => {
    const { navigation } = useNavigation()
    const { widgetInfo } = useContext(AppContext)
    const [ready, setReady] = useState(false)
    const [check, setCheck] = useState(false)

    useEffect(() => {
        checkMeeting()
        ReactGA.send({
            hitType: "pageview", 
            page: '/meeting-join-view'
        })

    }, [widgetInfo])

    const checkMeeting = async () => {
        ReactGA.event({
            category: 'Meetings',
            action: 'Checking if meeting is enabled',
        })
        if(!widgetInfo?.team.settings.defaultShouldCheckPresents){
            try {
                const res = await Axios.post(`${process.env.PREACT_APP_WIDGET_API}/v1/user/present`)
                // If res === 200
                setCheck(true)
            } catch (error) {
                // If res === 400
                setCheck(false)
                // console.log('error checking meeting', error)
            } finally {
                setReady(true)
            }
        } else {
            widgetInfo?.meeting?.urlLink ? setCheck(true) : setCheck(false)
            setReady(true)
        }
    }

    const joinMeeting = () => {
        navigation('/')
        window.open(`${widgetInfo?.meeting?.urlLink}`)
        ReactGA.event({
            category: 'Meetings',
            action: 'Joining Meeting',
        })
    }

    if (!ready)
        return (
            <MainLayout centered={true}>
                <div style={{ textAlign: 'center' }}>
                    <Text id="control.loading"></Text>
                </div>
            </MainLayout>
        )

    return (
        <MainLayout>
            <MeetingBlock>
                <img className="main-img" src={check ? meetIconOk : meetIconErr} style={{width:'96px'}} alt="" />
                <SecondaryTitle className="block-title">
                    <Text id="meeting.title" />
                </SecondaryTitle>
                <LeadTextBlock className="lead-text" center style={{ marginBottom: '0' }}>
                    {check ? <Text id="meeting.check_true" /> : <Text id="meeting.check_false" />}
                </LeadTextBlock>
                {check && (
                    <BlueButton
                        width={100}
                        // style={{ width: '100%' }}
                        className="join-button"
                        onClick={() => joinMeeting()}
                        aria-label="Join meeting"
                    >
                        <Text id="meeting.join" />
                    </BlueButton>
                )}
                <WhiteButton
                    width={100}
                    // style={{ width: '100%' }}
                    className="cancel-button"
                    onClick={() => navigation('/')}
                    style={!check && { marginTop: '20px' }}
                    aria-label="Cancel"
                >
                    {check ? <Text id="meeting.cancel" /> : <Text id="meeting.back" />}
                </WhiteButton>
            </MeetingBlock>
        </MainLayout>
    )
}

export default UserView

const MeetingBlock = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border:1px solid red; */
    padding: 20px 30px;
    .block-title {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .lead-text {
        margin-top: 0;
        padding: 0 25px;
    }
    .video-icon {
        font-size: 2.5rem;
        color: ${colors.blueEgg};
    }
    .join-button {
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .main-img{
        display:none;
    }
    @media only screen and (min-height : 500px) {
        .main-img{
            display:block;
        }
    }
`

const MeetingMembers = styled.div`
    display: flex;
    margin: 15px 0 0;
`
