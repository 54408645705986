import axios from 'axios'
import { useContext } from 'preact/hooks'
import { AppContext } from '../app'
import { UIContext } from '../context/UIContext'
import ReactGA from 'react-ga4'
import { IVoteCandidates } from '../interfaces/IWidgetInfo'
import { setGAErrorHasOccurred } from '../helpers/GAnalytics'
import { setGAStudentRecognition } from '../helpers/GAnalytics'

export type MandatoryVoteStatus = {
    shouldVote: boolean
    table: IVoteCandidates
    _algorithmId: string
}

interface VotingContext {
    voteStudent: (profileId: string | 'omit', algorithmToVote?: string, mandatoryVote?: boolean, mandatoryMentorVote?: boolean) => void
    didIVoteThisUser: (profileId: string) => boolean
}

// This hook handles voting within all widget voting buttons,
// It handles voting an specific profileId or 'omit' in mandatory vote view case.
export const useVoting = (): VotingContext => {
    // Used to show spinners while posting vote.
    // Used to fetch main widget info after voting
    const { widgetInfo, updateInfo, clearCache } = useContext(AppContext)
    const { setToast, dispatchNotification } = useContext(UIContext)

    // Takes a profileId and votes student
    const voteStudent = async (profileId: string, algorithmToVote?: string, mandatoryVote?: boolean, mandatoryMentorVote?: boolean) => {
        if (didIVoteThisUser(profileId) && (mandatoryVote === false || mandatoryVote === undefined)) {
            // If student is voted, unvote
            // Gets snapshot of previous voteData state to restore in case try fails
            const voteStatusSnapshot = widgetInfo?.me.voteData
            try {
                updateInfo({ me: { ...widgetInfo?.me, voteData: {} } })
                await axios.delete(`${process.env.PREACT_APP_WIDGET_API}/v1/vote`, {
                    data: {
                        _profileToId: profileId,
                        _algorithmId: algorithmToVote ? algorithmToVote : widgetInfo?.algorithm?._id,
                    },
                })
                setGAStudentRecognition(false, false, 'remove')
            } catch (error) {
                updateInfo({ me: { ...widgetInfo?.me, voteData: voteStatusSnapshot } })
                //setToast(['error', 'Failed to remove pulse. Try again later.'])
                dispatchNotification({
                    type: null,
                    payload: {
                        title: 'Error',
                        subtitle: 'Failed to remove pulse. Try again later.',
                    },
                })
                clearCache()
                setGAErrorHasOccurred('Error removing pulse to user', error)
            }
        } else {
            // If student is not voted, vote
            // Gets snapshot of previous voteData state to restore in case try fails
            const voteStatusSnapshot = widgetInfo?.me.voteData
            try {
                // it is verified that it is a mandatory vote in false so that the info is not updated
                if (mandatoryVote === false || mandatoryVote === undefined) {
                    updateInfo({
                        me: {
                            ...widgetInfo?.me,
                            voteData: {
                                createdAt: '',
                                _profileFromId: widgetInfo?.me.profile._id,
                                _profileToId: profileId,
                            },
                        },
                    })
                }

                await axios.post(
                    `${process.env.PREACT_APP_WIDGET_API}/v1/vote`,
                    profileId === 'omit'
                        ? {
                              type: 'blank',
                              _algorithmId: algorithmToVote ? algorithmToVote : widgetInfo?.algorithm?._id,
                          }
                        : {
                              _profileToId: profileId,
                              _algorithmId: algorithmToVote ? algorithmToVote : widgetInfo?.algorithm?._id,
                          }
                )
                setGAStudentRecognition(mandatoryVote || false, mandatoryMentorVote || false, profileId === 'omit' ? 'omit' : 'give')
            } catch (error) {
                // Restores snapshot
                updateInfo({ me: { ...widgetInfo?.me, voteData: voteStatusSnapshot } })
                //setToast(['error', 'Pulse failed. Try again later.'])
                dispatchNotification({
                    type: null,
                    payload: {
                        title: 'Error',
                        subtitle: 'Pulse failed. Try again later.',
                    },
                })
                clearCache()
                setGAErrorHasOccurred('Error giving pulse to user', error)
            }
        }

        return
    }

    const didIVoteThisUser = (profileId: string) => {
        return widgetInfo?.me.voteData._profileToId === profileId
    }

    return {
        voteStudent,
        didIVoteThisUser,
    }
}
