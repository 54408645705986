import axios from "axios"
import { useContext } from "preact/hooks"
import { AttendanceStatus } from "../interfaces/AttendanceStatus"
import { AppContext } from '../app'
import ReactGA from 'react-ga4'
import { UIContext } from "../context/UIContext"
import { IWidgetInfo } from "../interfaces/IWidgetInfo"
import { useVoting } from "../hooks/useVoting"

export const useAttendance = () => {
    const { setWidgetInfo, widgetInfo, setMandatoryVote } = useContext(AppContext)
    const { setCanSetPresent, canSetPresent } = useContext(UIContext)

    const markAttendance = async () => {
        try{

          const res = await axios.post<{ data: { present: boolean, status: AttendanceStatus, canSetPresent: boolean } }>(`${process.env.PREACT_APP_WIDGET_API}/v1/user/present`)
            if(res.data.data?.canSetPresent){
                updateLocalAttendance(res.data.data.status);
                setCanSetPresent(true);
            }else{
                setCanSetPresent(false)
            }

            const update = Object.assign(widgetInfo as IWidgetInfo, {
                me: {
                    ...widgetInfo?.me,
                    own: {
                        attending: AttendanceStatus.PRESENT
                    }
                }
            })
            widgetInfo && setWidgetInfo?.({ ...update })
            ReactGA.event({
                category: 'Engagement',
                action: 'Attendance',
            })

        } catch(error){
            console.error('Falló al marcar presente', error)
        }

        return canSetPresent
    }


    const updateLocalAttendance = (attendanceStatus: AttendanceStatus) => {
        const update = Object.assign(widgetInfo as IWidgetInfo, {
            me: {
                ...widgetInfo?.me,
                own: {
                    attending: attendanceStatus
                }
            }
        })
        widgetInfo && setWidgetInfo?.({ ...update })
    }



    return {
        markAttendance,
        updateLocalAttendance
    }


}