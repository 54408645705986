import Modal from 'react-modal'
import { TextButton } from '../../components/Buttons/TextButton'
import styled from 'styled-components'
import helpResquest from '../../assets/help_request.jpg'
import helpResquestSelected from '../../assets/help_request_selected.png'
import helpRequestCancel from '../../assets/help_request_cancel.jpg'
import mentorJoined from '../../assets/mentor-joined.png'
import mentorLeaving from '../../assets/mentor-leaving.png'
import mentorRoleBegins from '../../assets/mentors-badge.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'preact/hooks'
import { colors } from '../../style/variables'
import { TextBlock, SecondaryTitle } from '../Type/Title'
import { AppContext } from '../../app'
import { HelpRequestStatus } from '../../interfaces/IWidgetInfo'
import { Text, useText } from 'preact-i18n'
import { useHelpRequest } from '../../hooks/useHelpRequest'
import { UIContext } from '../../context/UIContext'
import { ProgressBar } from '../Storie/ProgressBar'
import { capitalizeFullName } from '../../helpers/capitalize'
import { useTimer } from 'react-timer-hook'
import dayjs from 'dayjs'
import { LinkWithQuery } from '../LinkWithQuery'
import { BlueButton } from '../Buttons/BlueButton'
import { setGAWidgetMentorHelpRequest } from '../../helpers/GAnalytics'

Modal.setAppElement('body')

const HelpRequestModal = () => {
    const { widgetInfo, updateInfo, fetchInfo, setMentorNoticeSeen } = useContext(AppContext)
    const { setRequestNotification, requestModal, setRequestModal } = useContext(UIContext)
    const { updateHelpRequest, requestHelp, cancelHelp, returnMentor, mentorInput } = useHelpRequest()
    const [quitBtnEnabled, setQuitBtnEnabled] = useState(true)

    // Setting up a timer to show only when mentor is selected to provide help (mentor-selected)
    const { seconds, start, restart, pause } = useTimer({
        autoStart: false,
        expiryTimestamp: dayjs().add(50, 's').toDate(),
        onExpire: () => expireHelpRequest(),
    })
    const [mentorExpirePercent, setMentorExpirePercent] = useState<number>(100)

    useEffect(() => {
        if (requestModal === 'mentor-selected') {
            start()
        }
    }, [requestModal])

    useEffect(() => {
        if (widgetInfo?.table?.helpRequest?.status === HelpRequestStatus.CANCELED) {
            restart(dayjs().add(50, 's').toDate(), false)
        }
    }, [widgetInfo?.table?.helpRequest?.status])

    useEffect(() => {
        setMentorExpirePercent(Math.floor((seconds * 100) / 50))
    }, [seconds])

    const returnMentorLocal = () => {
        setQuitBtnEnabled(false)
        returnMentor()
        setTimeout(() => {
            setQuitBtnEnabled(true)
        }, 4000)
    }

    const startMentorship = () => {
        restart(dayjs().add(50, 's').toDate(), false)
        setRequestModal(undefined)
        updateInfo({ table: { ...widgetInfo?.table, helpRequest: null } })
        setRequestNotification({ status: 'mentor-help' })
    }

    const acceptHelpRequest = () => {
        setGAWidgetMentorHelpRequest({
            action: 'accepted',
            idTrakingHelpRequest: widgetInfo?.table?.helpRequest?._id,
        })
        console.log('Accepted HR')
        setRequestModal(undefined)
        restart(dayjs().add(50, 's').toDate(), false)
        mentorInput('accept')
    }

    const rejectHelpRequest = () => {
        setGAWidgetMentorHelpRequest({
            action: 'rejected',
            idTrakingHelpRequest: widgetInfo?.table?.helpRequest?._id,
        })
        console.log('Rejected HR')
        setRequestModal(undefined)
        restart(dayjs().add(50, 's').toDate(), false)
        mentorInput('decline')
        setRequestNotification({ status: 'mentor-rejected' })
        updateHelpRequest({
            from: '',
            status: HelpRequestStatus.REJECTED,
            _id: '',
        })
    }

    const expireHelpRequest = () => {
        setGAWidgetMentorHelpRequest({
            idTrakingHelpRequest: widgetInfo?.table?.helpRequest?._id,
            action: 'expired'
        })
        console.log('Expired HR')
        setGAWidgetMentorHelpRequest({
            action: 'expired',
            idTrakingHelpRequest: widgetInfo?.table?.helpRequest?._id,
        })
        setRequestModal(undefined)
        restart(dayjs().add(50, 's').toDate(), false)
        mentorInput('expired')
        setRequestNotification({ status: 'hr-expired' })
        updateHelpRequest({
            from: '',
            status: HelpRequestStatus.EXPIRED,
            _id: '',
        })
    }

    const { link } = useText({
        link: 'mentor.module.link',
    })

    useEffect(() => {
        if (requestModal === 'mentor-selected') {
            setGAWidgetMentorHelpRequest({
                idTrakingHelpRequest: widgetInfo?.table?.helpRequest?._id,
                action: 'displayed',
            })
        }
    }, [requestModal])

    switch (requestModal) {
        case 'request':
            return (
                <Modal isOpen={!!requestModal} style={modalStyles}>
                    <ModalContainer>
                        <img src={helpResquest} alt="" />
                        <SecondaryTitle style={{ marginBottom: '10px' }}>
                            <Text id="help-request.modal.request.title">Solicita ayuda externa</Text>
                        </SecondaryTitle>
                        <Paragraph>
                            <Text id="help-request.modal.request.desc">
                                Al solicitar ayuda externa llamarás a una persona idónea en la materia para aclarar dudas que el equipo no ha logrado
                                resolver.
                            </Text>
                        </Paragraph>
                        <BlueButton
                            onClick={() => {
                                requestHelp(), setRequestModal(undefined)
                            }}
                        >
                            <Text id="help-request.modal.request.cta">Solicitar ayuda</Text>
                        </BlueButton>

                        <TextButton onClick={() => setRequestModal(undefined)}>Cancelar</TextButton>

                        <NavigationIcon style={{ marginLeft: 'auto' }} onClick={() => setRequestModal(undefined)}>
                            <FontAwesomeIcon icon={['fal', 'times']} />
                        </NavigationIcon>
                    </ModalContainer>
                </Modal>
            )

        case 'canceled':
            return (
                <Modal isOpen={!!requestModal} style={modalStyles}>
                    <ModalContainer>
                        <img src={helpRequestCancel} alt="" />
                        <SecondaryTitle style={{ marginBottom: '10px' }}>
                            <Text id="help-request.modal.cancel.title">¿Quieres cancelar la solicitud de ayuda?</Text>
                        </SecondaryTitle>
                        <Paragraph>
                            <Text id="help-request.modal.cancel.desc">
                                Si cancelas la solicitud, el equipo no recibirá ayuda externa en esta oportunidad.
                            </Text>
                        </Paragraph>
                        <BlueButton
                            onClick={() => {
                                cancelHelp()
                                setRequestModal(undefined)
                            }}
                        >
                            <Text id="help-request.modal.cancel.cta">Cancelar solicitud</Text>
                        </BlueButton>
                        <TextButton onClick={() => setRequestModal(undefined)}>Mantener solicitud actual</TextButton>
                        <NavigationIcon style={{ marginLeft: 'auto' }} onClick={() => setRequestModal(undefined)}>
                            <FontAwesomeIcon icon={['fal', 'times']} />
                        </NavigationIcon>
                    </ModalContainer>
                </Modal>
            )

        case 'requested-by-me':
            return (
                <Modal isOpen={!!requestModal} style={modalStyles}>
                    <ModalContainer>
                        <img src={helpResquest} alt="" />
                        <SecondaryTitle style={{ marginBottom: '10px' }}>
                            <Text id="help-request.modal.requestedByMe.title">Ya solicitaste ayuda externa</Text>
                        </SecondaryTitle>
                        <Paragraph>
                            <Text id="help-request.modal.requestedByMe.desc">
                                Si quieres realizar una nueva solicitud primero debes cancelar la actual.
                            </Text>
                        </Paragraph>
                        <BlueButton onClick={() => setRequestModal(undefined)}>
                            <Text id="help-request.modal.requestedByMe.cta">Entendido</Text>
                        </BlueButton>
                        <NavigationIcon style={{ marginLeft: 'auto' }} onClick={() => setRequestModal(undefined)}>
                            <FontAwesomeIcon icon={['fal', 'times']} />
                        </NavigationIcon>
                    </ModalContainer>
                </Modal>
            )

        case 'requested':
            return (
                <Modal isOpen={!!requestModal} style={modalStyles}>
                    <ModalContainer>
                        <img src={helpResquest} alt="" />
                        <SecondaryTitle style={{ marginBottom: '10px' }}>
                            <Text id="help-request.modal.requested.title">La ayuda ya fue solicitada</Text>
                        </SecondaryTitle>
                        <Paragraph>
                            {capitalizeFullName(
                                `${widgetInfo?.table?.helpRequest?.from._userId?.name} ${widgetInfo?.table?.helpRequest?.from._userId?.lastname}`
                            )}{' '}
                            <Text id="help-request.modal.requested.desc">
                                solicitó ayuda externa. Por favor aguarda unos minutos a que ingrese una persona idónea en la materia para aclarar
                                dudas.
                            </Text>
                        </Paragraph>
                        <BlueButton onClick={() => setRequestModal(undefined)}>
                            <Text id="help-request.modal.requestedByMe.cta">Entendido</Text>
                        </BlueButton>
                        <NavigationIcon style={{ marginLeft: 'auto' }} onClick={() => setRequestModal(undefined)}>
                            <FontAwesomeIcon icon={['fal', 'times']} />
                        </NavigationIcon>
                    </ModalContainer>
                </Modal>
            )

        case 'mentor-joined':
            return (
                <Modal isOpen={!!requestModal} style={modalStyles}>
                    <ModalContainer>
                        <img src={mentorJoined} alt="" />
                        <SecondaryTitle style={{ marginBottom: '10px' }}>
                            <Text id="help-request.modal.mentor-joined.title" />
                        </SecondaryTitle>
                        <Paragraph>
                            <Text id="help-request.modal.mentor-joined.desc" />
                        </Paragraph>
                        <BlueButton onClick={() => startMentorship()}>
                            <Text id="help-request.modal.mentor-joined.cta" />
                        </BlueButton>
                        <NavigationIcon style={{ marginLeft: 'auto' }} onClick={() => startMentorship()}>
                            <FontAwesomeIcon icon={['fal', 'times']} />
                        </NavigationIcon>
                    </ModalContainer>
                </Modal>
            )

        case 'mentor-leaving':
            return (
                <Modal isOpen={!!requestModal} style={modalStyles}>
                    <ModalContainer>
                        <img src={mentorLeaving} alt="" />
                        <SecondaryTitle style={{ marginBottom: '10px' }}>
                            <Text id="help-request.modal.mentor-leaving.title" />
                        </SecondaryTitle>
                        <Paragraph>
                            <Text id="help-request.modal.mentor-leaving.desc" />
                        </Paragraph>
                        <BlueButton disabled={!quitBtnEnabled} className="blue-button-primary" width={100} onClick={() => returnMentorLocal()}>
                            <Text id="help-request.modal.mentor-leaving.cta" />
                        </BlueButton>

                        <TextButton onClick={() => setRequestModal(undefined)}>Permanecer en este equipo</TextButton>

                        <NavigationIcon colorIcon={colors.blueEgg} style={{ marginLeft: 'auto' }} onClick={() => setRequestModal(undefined)}>
                            <FontAwesomeIcon icon={['fal', 'times']} />
                        </NavigationIcon>
                    </ModalContainer>
                </Modal>
            )

        case 'in-progress':
            return (
                <Modal isOpen={!!requestModal} style={modalStyles}>
                    <ModalContainer>
                        <img src={helpResquest} alt="" />
                        <SecondaryTitle style={{ marginBottom: '10px' }}>
                            <Text id="help-request.modal.in-progress.title" />
                        </SecondaryTitle>
                        <Paragraph>
                            <Text id="help-request.modal.in-progress.desc" />
                        </Paragraph>
                        <BlueButton onClick={() => setRequestModal(undefined)}>
                            <Text id="help-request.modal.in-progress.cta" />
                        </BlueButton>
                    </ModalContainer>
                </Modal>
            )

        case 'mentor-selected':
            return (
                <Modal isOpen={!!requestModal} style={modalStyles}>
                    <ModalContainer>
                        <img src={helpResquestSelected} alt="" />
                        <SecondaryTitle style={{ marginBottom: '10px' }}>Solicitud de ayuda</SecondaryTitle>
                        <Paragraph>Un equipo está intentando resolver una duda de un ejercicio que tú ya resolviste y necesita de ti.</Paragraph>
                        <div style={{ width: '50%', marginBottom: '20px', marginTop: '5px' }}>
                            <ProgressBar percent={mentorExpirePercent} index={1} numberStory={1} />
                            <span style={{ fontSize: '10px', color: '#073045' }}>{seconds} segundos restantes</span>
                        </div>
                        <BlueButton className="blue-button-primary" width={100} onClick={() => acceptHelpRequest()}>
                            Aceptar solicitud
                        </BlueButton>
                        <TextButton onClick={() => rejectHelpRequest()}>
                            <Text id={'control.reject'}>Rechazar</Text>
                        </TextButton>
                    </ModalContainer>
                </Modal>
            )

        // This modal is not part of the HELP REQUEST FLOW. We are only using this logic to show it to mentores once they first load the app.
        case 'mentor-role-begins':
            return (
                <Modal isOpen={!!requestModal} style={modalStyles}>
                    <ModalContainer>
                        <img src={mentorRoleBegins} alt="mentors badge" />
                        <SecondaryTitle style={{ marginBottom: '10px' }}>
                            <Text id="mentor.module.title" />
                        </SecondaryTitle>
                        <Paragraph>
                            <Text id="mentor.module.msg">
                                Te hemos seleccionado por tu liderazgo, conocimiento y capacidad para explicar para que ayudes a resolver dudas de
                                otros equipos.
                            </Text>
                        </Paragraph>
                        <BlueButton
                            onClick={() => {
                                setMentorNoticeSeen(true)
                                setRequestModal(undefined)
                            }}
                        >
                            <Text id="mentor.module.goit" />
                        </BlueButton>
                        <TextButton
                            onClick={() => {
                                setMentorNoticeSeen(true)
                                setRequestModal(undefined)
                            }}
                        >
                            <LinkWithQuery to={'/tutorial/mentor'}>
                                <span style={{ fontSize: '12px' }}>{link}</span>
                            </LinkWithQuery>
                        </TextButton>
                    </ModalContainer>
                </Modal>
            )
    }

    return null
}

export default HelpRequestModal

export const Paragraph = styled(TextBlock)`
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 15px;
`

export const ModalContainer = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media only screen and (min-height: 450px) {
        min-height: 330px;
    }
    img {
        display: none;
        @media only screen and (min-height: 450px) {
            display: block;
        }
        padding: 20px 0 10px;
        width: 230px;
        flex: 1;
        object-fit: contain;
    }
`

export const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 55, 80, 0.98)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        minHeight: '250px',
        bottom: 'auto',
        // minHeight:'366px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        width: '80%',
        boxSizing: 'border-box',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
    },
}

export const NavigationIcon = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 22px;
    color: ${colors.darkGreyEgg};
    background: none;
    border: none;
    &:hover {
        opacity: 0.7;
    }
    cursor: pointer;
    &:hover {
        color: ${colors.blueEgg};
        fill: ${colors.blueEgg};
    }
`
