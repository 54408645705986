import Axios from 'axios'

export const useNotifications = () => {
    const sendNotification = async (
        title: string,
        message: string,
        options?: Record<string, unknown>
    ) => {
        return Axios.post(`${process.env.PREACT_APP_WIDGET_API}/v1/user/hit`, {
            title: title,
            message: message,
            ...options,
        }).then(
            response => {
                console.log('Notificación enviada')
                return response
            },
            error => {
                const errorMsg =
                    error.message ||
                    (error.data && error.data.message) ||
                    'No se ha podido contactar al profesor'

                console.log(errorMsg, 'error')
            }
        )
    }

    const cancelNotification = async (
        title: string,
        message: string,
        options?: Record<string, unknown>
    ) => {
        try {
            const res = await Axios.patch(`${process.env.PREACT_APP_WIDGET_API}/v1/notification/cancel`, {
                type: 'help',
                title: title,
                message
            })
            // console.log('res', res)            
        } catch (error) {
            console.log(error)
        }
    }

    return { sendNotification, cancelNotification }
}
