import { IWidgetInfo, IWidgetTable } from '../../interfaces/IWidgetInfo'
import { StateUpdater, useContext, useEffect, useState } from 'preact/hooks'
import styled, { css } from 'styled-components'

import BoltColor from '../../assets/boltColor.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HCAType } from '../../interfaces/IWidgetInfo'
import { HelpModalType } from '../../interfaces/IWidgetInfo'
import { IconHROff } from '../icons/IconHROff'
import { IconHROn } from '../icons/IconHROn'
import { IconPulse } from '../icons/IconPulse'
import ToolTip from '../../components/Tooltip'
import { UIContext } from '../../context/UIContext'
import { WhiteButton } from '../../components/Buttons/WhiteButton'
import { colors } from '../../style/variables'
import { useNavigation } from '../../hooks/useNavigation'
import { useText } from 'preact-i18n'
import { AppContext } from '../../app'

interface IProps {
    widgetInfo?: IWidgetInfo
    helpRequest: IWidgetTable['helpRequest']
}

const { PREACT_APP_PARENT_URL = '' } = process.env

const ActionsBlock = ({ widgetInfo, helpRequest }: IProps) => {
    const { navigation } = useNavigation()
    const { setShowActivateTeam, disabledActivateTeam, setDisabledActivateTeam, setRequestModal } = useContext(UIContext)
    const { widgetSource } = useContext(AppContext)
    const [showTooltip, setShowTooltip] = useState<'table-vote' | 'help-request' | 'join-meeting' | 'active-team' | null>(null)
    const [hideHelpRequestFeature, setHideHelpRequestFeature] = useState(false)
    const [hideActiveTeam, setHideActiveTeam] = useState(false)
    const [cameraDropdown, setCameraDropdown] = useState(false)

    const isZoomSDK = widgetInfo?.meeting?.type === 'zoom-sdk'
    const isVideoSDKSource = widgetSource === 'video-sdk'

    const { likeTableLabel, helpRequestLabel, onlineMeetingLabel, activateTeam } = useText({
        likeTableLabel: 'actions.labels.likeTeamLabel',
        helpRequestLabel: 'actions.labels.helpRequestLabel',
        onlineMeetingLabel: 'actions.labels.onlineMeetingLabel',
        activateTeam: 'actions.labels.activateTeam',
    })

    useEffect(() => {
        if (disabledActivateTeam) {
            setTimeout(() => {
                setDisabledActivateTeam(false)
            }, 120000)
        }
    }, [disabledActivateTeam])

    useEffect(() => {
        setHideHelpRequestFeature(
            process.env.PREACT_APP_ENABLED_HELP_REQUEST !== 'true' ||
                widgetInfo?.team.settings.hideAttendanceStats === true ||
                widgetInfo?.team.settings.algorithmType !== HCAType.HCA4 ||
                widgetInfo?.team.settings.enabledHelpRequest === false
        )
        setHideActiveTeam(!widgetInfo?.team.settings.enabledActivationTeam === true)
    }, [])

    const getMyPosition = () => {
        return widgetInfo?.table?.positions.findIndex(position => widgetInfo.me.profile._id === position._profileId)
    }

    const setProperRequestModal = () => {
        if (!helpRequest || helpRequest.status === 'ended') {
            setRequestModal('request')
        } else {
            if (helpRequest.status === 'in-progress' && widgetInfo?.algorithm?.isMentor && getMyPosition() === 0) {
                getMyPosition()
                setRequestModal('request')
                return
            }
            if (helpRequest.status === 'no-mentor-available') {
                setRequestModal('request')
                return
            }
            if (helpRequest.status === 'in-progress') {
                setRequestModal('in-progress')
                return
            }
            if (helpRequest.status === 'canceled') {
                setRequestModal('request')
                return
            }
            if (helpRequest.status === 'rejected' || helpRequest.status === 'expired') {
                return
            }
            if (helpRequest.from._profileId === widgetInfo?.me.profile._id) {
                setRequestModal('requested-by-me')
            } else {
                setRequestModal('requested')
            }
        }
    }

    const joinMeeting = (device: 'phone' | 'desktop') => {
        if (widgetInfo?.meeting?.type === 'zoom-sdk') {
            window?.top?.postMessage(
                {
                    eventName: 'join-sdk-meeting',
                    algorithmId: widgetInfo?.algorithm?._id,
                    device: device,
                },
                PREACT_APP_PARENT_URL
            )
        } else widgetInfo?.meeting ? navigation('/meeting') : null
        cameraDropdown && toggleCameraDropdown()
    }

    const toggleCameraDropdown = () => {
        setCameraDropdown(prev => !prev)
    }

    return (
        <ActionsContainer>
            {hideHelpRequestFeature === false && (
                <BtnContainer onMouseOver={() => setShowTooltip('help-request')} onMouseLeave={() => setShowTooltip(null)}>
                    <BtnIcon
                        status={helpRequest?.status}
                        disabled={!widgetInfo?.table}
                        onClick={() => setProperRequestModal()}
                        aria-label="Request help"
                    >
                        {helpRequest?.status === 'requested' ? <IconHROn className="icon" /> : <IconHROff className="icon" />}
                    </BtnIcon>
                    {showTooltip === 'help-request' && <ToolTip content={helpRequestLabel} direction="top" delay={100} />}
                </BtnContainer>
            )}
            {hideActiveTeam === false && (
                <BtnContainer onMouseOver={() => setShowTooltip('active-team')} onMouseLeave={() => setShowTooltip(null)}>
                    <BtnIcon disabled={!widgetInfo?.table || disabledActivateTeam} onClick={() => setShowActivateTeam(true)} aria-label="Active Team">
                        {isZoomSDK ? <IconHROff className="icon" /> : <FontAwesomeIcon className="icon" icon={['fas', 'wave-pulse']} />}
                    </BtnIcon>
                    {showTooltip === 'active-team' && <ToolTip content={isZoomSDK ? helpRequestLabel : activateTeam} direction="top" delay={100} />}
                </BtnContainer>
            )}

            {!isVideoSDKSource && (
                <div style={{ display: 'flex', width: '100%' }}>
                    {!hideActiveTeam && !hideHelpRequestFeature && <Border />}
                    <BtnContainer style={{ display: 'flex' }}>
                        <BtnIcon
                            style={{ borderRadius: isZoomSDK ? '12px 0px 0px 12px' : '12px', flex: '1' }}
                            disabled={!widgetInfo?.meeting}
                            aria-label="Join meeting"
                            onClick={() => joinMeeting('desktop')}
                            onMouseOver={() => setShowTooltip('join-meeting')}
                            onMouseLeave={() => setShowTooltip(null)}
                        >
                            <FontAwesomeIcon className="icon" icon={['fas', 'video']} />
                        </BtnIcon>
                        {showTooltip === 'join-meeting' && (
                            <ToolTip
                                showContentDirection={hideActiveTeam === false ? 'left' : 'center'}
                                content={onlineMeetingLabel}
                                direction="top"
                                delay={100}
                            />
                        )}
                        {isZoomSDK && (
                            <BtnIcon
                                style={{ borderRadius: isZoomSDK ? '0px 12px 12px 0px' : '12px', padding: '0 15px', flex: '0' }}
                                disabled={!widgetInfo?.meeting}
                                aria-label="Join meeting"
                                onClick={toggleCameraDropdown}
                            >
                                <FontAwesomeIcon className="icon" icon={['fas', 'chevron-down']} />
                            </BtnIcon>
                        )}
                        {isZoomSDK && cameraDropdown && (
                            <CameraDropdown>
                                <BtnIcon onClick={() => joinMeeting('phone')}>Mostrar QR</BtnIcon>
                            </CameraDropdown>
                        )}
                    </BtnContainer>
                </div>
            )}
        </ActionsContainer>
    )
}

export default ActionsBlock

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    /* grid-template-columns: repeat(3, 1fr); */
    grid-gap: 10px;
    margin-bottom: 10px;
`

const CameraDropdown = styled.div`
    position: absolute;
    top: 45px;
    right: 0px;
    button {
        width: 150px;
    }
`

const Border = styled.div`
    height: 40px;
    border-left: 1px solid #ededed;
    margin-right: 10px;
`

export const BtnContainer = styled.div`
    width: 100%;
    position: relative;
`

export const BtnIcon = styled(WhiteButton)`
    background-color: ${({ status }) => (status === 'requested' ? '#00A3FF' : 'none')};
    height: 40px;
    box-shadow: 0px 0px 20px #00375014;
    border: 1px solid ${colors.borders};
    border-radius: 12px;
    width: 100%;
    .icon {
        margin-right: 0;
        font-size: 18px;
        color: ${colors.darkGreyEgg};
    }
    .icon-pulse-svg {
        display: block;
    }
    .icon-pulse-img {
        display: none;
    }
    &:not(:disabled):hover {
        /* background-color: #4c9aff !important; */
        .icon {
            color: #4c9aff;
        }
        .icon-pulse-img {
            display: block;
        }

        .icon-pulse-svg {
            display: none;
        }
    }
    ${({ voted }) =>
        voted &&
        css`
            background: ${colors.darkGreyEgg};
            color: white;
            .icon {
                color: white;
            }

            .icon-pulse-svg {
                display: none;
            }

            .icon-pulse-img {
                display: block;
            }
        `}

    .icon-active {
        color: white;
    }
`
