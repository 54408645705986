import styled from 'styled-components'
import { StrongNumber } from '../components/Type/StrongNumber'
import { TextBlock } from '../components/Type/Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AvatarEgg from '../components/Avatar/AvatarEgg'
import { colors } from '../style/variables'
import { IUserStatsResponse, TablePos } from '../interfaces/IWidgetInfo'
import { useEffect, useState, useContext } from 'preact/hooks'
import axios from 'axios'
import Gr from '../assets/given-recogn.svg'
import Rr from '../assets/received-recogn.svg'
import { MarkupText } from 'preact-i18n'
import { AppContext } from '../app'
import ButtonPulse from './Buttons/Pulse/ButtonPulse'

interface IProps {
    user: TablePos
    isUserVoted: boolean
    isMe: boolean
    isUserFacilitator: boolean
}

const StatsResume = ({ user, isUserVoted, isMe, isUserFacilitator }: IProps) => {
    const [userStats, setUserStats] = useState<IUserStatsResponse['data'] | undefined>(undefined)
    const { widgetInfo, clearCache } = useContext(AppContext)
  
    const fetchStats = async () => {
        try {
            const res = await axios.get<IUserStatsResponse>(`${process.env.PREACT_APP_WIDGET_API}/v1/user/stats/${user?._profileId}`)
            setUserStats(res.data.data)
        } catch (error) {
            console.log('Error obtaining user stats', error)
            clearCache()
        }
    }

    useEffect(() => {
        user && fetchStats()
    }, [user])

    return (
        <Resume>
            <AvatarBlock isMe={isMe} isMentor={user?.isMentor}>
                <AvatarEgg
                    title={`${user?._userId.name} ${user?._userId.lastname}`}
                    size={102}
                    src={user?._userId?.urlImage ?? ''}
                    isMe={isMe}
                    facilitator={isUserFacilitator}
                    user={user}
                    isEditable={isMe ? true : false}
                    isMentor={user?.isMentor}
                    style={{ marginBottom: 14.5 }}
                />

                {!isMe && (!widgetInfo?.table?.isMentorTeam ? !user?.isMentor : true) && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ButtonPulse  item={user} expanded={true} /> 
                    </div>
                )}
            </AvatarBlock>
            {userStats ? (
                <StatsGridBlock>
                    <div className="grid-cont">
                        <StatItem>
                            <div className="inner">
                                <img src={Rr} width={20} style={{ marginBottom: '8px' }} alt="" />
                                <StrongNumber>{userStats?.receivedVotes}</StrongNumber>
                                <TextBlock
                                    style={{
                                        textAlign: 'center',
                                        lineHeight: '12px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        fontSize: '10px !important',
                                    }}
                                >
                                    <MarkupText id="stats.taken_votes" />
                                </TextBlock>
                            </div>
                        </StatItem>
                        <StatItem>
                            <div className="inner">
                                <img src={Gr} width={18} style={{ marginBottom: '8px' }} alt="" />
                                <StrongNumber>{userStats?.givenVotes}</StrongNumber>
                                <TextBlock
                                    style={{
                                        textAlign: 'center',
                                        lineHeight: '12px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <MarkupText id="stats.given_votes" />
                                </TextBlock>
                            </div>
                        </StatItem>
                        <StatItem>
                            <div className="inner">
                                <FontAwesomeIcon icon={['fas', 'medal']} className="icon" style={{ color: '#FFBA00' }} />
                                <StrongNumber>{userStats?.timesFacilitator}</StrongNumber>
                                <TextBlock
                                    style={{
                                        textAlign: 'center',
                                        lineHeight: '12px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <MarkupText id="stats.times_facilitator" />
                                </TextBlock>
                            </div>
                        </StatItem>

                        <StatItem>
                            <div className="inner">
                                <FontAwesomeIcon icon={['fas', 'user-check']} className="icon" style={{ color: '#15C1B0' }} />
                                <StrongNumber>{userStats?.assistance.timesPresent}</StrongNumber>
                                <TextBlock
                                    style={{
                                        textAlign: 'center',
                                        lineHeight: '12px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <MarkupText id="stats.assistance.present" />
                                </TextBlock>
                            </div>
                        </StatItem>
                        <StatItem>
                            <div className="inner">
                                <FontAwesomeIcon icon={['fas', 'user-clock']} className="icon" style={{ color: colors.blueEgg }} />
                                <StrongNumber>{userStats?.assistance.timesDelay}</StrongNumber>
                                <TextBlock
                                    style={{
                                        textAlign: 'center',
                                        lineHeight: '12px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <MarkupText id="stats.assistance.late">Tardanzas</MarkupText>
                                </TextBlock>
                            </div>
                        </StatItem>
                        <StatItem>
                            <div className="inner">
                                <FontAwesomeIcon icon={['fas', 'user-slash']} className="icon" style={{ color: '#FF647C' }} />
                                <StrongNumber>{userStats?.assistance.timesAbsent}</StrongNumber>
                                <TextBlock
                                    style={{
                                        textAlign: 'center',
                                        lineHeight: '12px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <MarkupText id="stats.assistance.absent">Ausentes</MarkupText>
                                </TextBlock>
                            </div>
                        </StatItem>
                    </div>
                </StatsGridBlock>
            ) : (
                <Stats>
                    <FontAwesomeIcon className="icon" icon={['fal', 'spinner']} spin />
                </Stats>
            )}
        </Resume>
    )
}

export default StatsResume

const Resume = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* border:1px solid red; */
    padding: 10px 30px 25px 30px;
    row-gap: 12px;
`

const AvatarBlock = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #00375014;
    border: 1px solid #EDEDED;
    border-radius: 12px;
    border: 1px solid #EDEDED;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 51px;
    padding-bottom: ${props => (props.isMe || props.isMentor ? '56px' : '29px')};
    /* margin-top: ${props => (props.isMe || props.isMentor ? '17px' : '5px')};
    margin-bottom: ${props => (props.isMe || props.isMentor ? '40px' : '5px')};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
        margin-top: 20px;
        margin-bottom: 20px;
    } */
`
const Stats = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 30px 0 30px;
    /* background-color:red; */
    .stat-item {
        text-align: center;
        padding: 0 20px;
        .icon {
            margin-top: 5px;
            margin-bottom: 10px;
            font-size: 10px;
        }
        .class {
            color: #f79595;
        }
        .trophy {
            color: #47a5ff;
        }
        .egg-up {
            color: ${colors.eggColor};
        }
        .egg-down {
            color: ${colors.darkGreyEgg};
        }
    }
`

const StatsGridBlock = styled.div`
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    width: 100%;
    align-items: flex-start;
    .grid-cont {

        width: 100%;
        max-height: 121px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }
`

const StatItem = styled.div`
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 12px;
    text-align: center;
    opacity: 1;
    height: 100%;


    .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        padding-top: 13px;
        height: 100%;
        p {
            line-height: 13px;
        }
        .markup {
            display: block;
            font-size: 11px !important;
            line-height: 12px;
            color: #003750;
            font-weight: 500;
        }
        .icon {
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
`
